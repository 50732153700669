:root {
  /* Primary */
  --gold-300: #ffc261;
  --gold-400: #ffae2e;
  --gold-500: #fa9900;
  --gold-600: #c77a00;
  --gold-700: #945b00;

  /* Secondary */
  --velvet-300: #ec8d92;
  --velvet-400: #e56268;
  --velvet-500: #dd343d;
  --velvet-600: #c02028;
  --velvet-700: #94191f;

  /* Support */
  /* Navy */
  --navy-300: #92cbe8;
  --navy-400: #68b7df;
  --navy-500: #3ea3d6;
  --navy-600: #2788b9;
  --navy-700: #1e698f;

  /* Nature */
  --nature-300: #a6d4b8;
  --nature-400: #84c39d;
  --nature-500: #61b281;
  --nature-600: #4a9768;
  --nature-700: #397450;

  /* Grey */
  /* Dark */
  --dark-300: #373b3e;
  --dark-400: #2e3133;
  --dark-500: #242729;
  --dark-600: #1a1c1e;
  --dark-700: #111213;

  /* Soft */
  --soft-300: #afb6bb;
  --soft-400: #a4acb2;
  --soft-500: #99a2a8;
  --soft-600: #8e989f;
  --soft-700: #737f87;

  /* Light */
  --light-300: #fafafa;
  --light-400: #f5f5f5;
  --light-500: #f0f0f0;
  --light-600: #ebebeb;
  --light-700: #e6e6e6;
  --grey-light-700: #d9d9d9;

  /* Neutral */
  /* White */
  --white: #ffffff;

  /* With transparency */
  --nature-400-op-10: #84c39d1a;
  --gold-400-op-10: #ffae2e1a;
  --velvet-400-op-10: #e562681a;
}
