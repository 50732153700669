/* Default button */
/* PRIMARY BLACK (type='default' -or nothing-) */
.ant-btn {
  background-color: var(--dark-700);
  gap: 8px;
  border-radius: 40px;
  border: 1.5px solid var(--dark-700);
  color: var(--white);
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 0px 24px;
  height: 40px;
  min-width: min-content;
  width: 195px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn:hover {
  border: 1.5px solid var(--dark-300);
  background: var(--dark-300);
  color: var(--white);
}

.ant-btn:active {
  border: 1.5px solid var(--dark-500);
  background: var(--dark-500);
  color: var(--white);
}

.ant-btn:focus {
  background: var(--dark-700);
  border: 1.5px solid var(--light-700);
  color: var(--white);
}

.ant-btn-ghost {
  background-color: var(--white);
  gap: 8px;
  border-radius: 40px;
  border: 1.5px solid var(--dark-700);
  color: var(--dark-700);
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 0px 24px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}
/* SECONDARY - OUTLINED (type='ghost') */
.ant-btn-ghost:hover {
  border: 1.5px solid var(--soft-700);
  background: var(--white);
  color: var(--soft-700);
}

.ant-btn-ghost:active {
  border: 1.5px solid var(--dark-300);
  background: var(--white);
  color: var(--dark-300);
}

.ant-btn-ghost:focus {
  color: var(--dark-700);
  background: var(--light-300);
  border: 2px solid var(--light-700);
  outline: 2px solid rgba(55, 31, 206, 0.3);
}

.ant-btn > .anticon {
  line-height: 0;
}

/* Sizing */
.ant-btn-sm {
  height: 32px;
}

.ant-btn-lg {
  height: 48px;
}

/* TYPES */

/*Primary*/
/* PRIMARY - GOLD (type='primary') */
.ant-btn-primary {
  border: none;
  background: var(--gold-500);
  border-color: var(--gold-500);
  color: var(--white);
}

.ant-btn-primary:hover {
  border: none;
  background: var(--gold-300);
  border-color: var(--gold-300);
  color: var(--white);
}

.ant-btn-primary:active {
  border: none;
  background: var(--gold-400);
  border-color: var(--gold-400);
  color: var(--white);
}

.ant-btn-primary:focus {
  border: 1.5px solid var(--gold-300);
  background: var(--gold-500);
  color: var(--white);
  outline: 2px solid rgba(55, 31, 206, 0.3);
}

/*BLACK*/
.btn-black {
  background: var(--dark-700);
}

.btn-black:hover {
  background: var(--dark-300);
}

.btn-black:active {
  background: var(--dark-500);
}

.btn-black:focus {
  border: 2px solid rgba(55, 31, 206, 0.3);
  background: var(--dark-700);
}

/* WARNING */
.ant-btn-dangerous {
  border: 1.5px solid var(--velvet-600);
  color: var(--velvet-600);
}

.ant-btn-dangerous:hover {
  border: 1.5px solid var(--velvet-300);
  color: var(--velvet-400);
}

.ant-btn-dangerous:active {
  border: 1.5px solid var(--velvet-500);
  color: var(--velvet-500);
}

.ant-btn-dangerous:focus {
  outline: 2px solid rgba(55, 31, 206, 0.3);
  color: var(--velvet-600);
}

/*LINK*/

.ant-btn-link {
  line-height: 16px;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1.5px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: auto;
  padding: 0;
  font-size: 12px;
  border-radius: 0;
  color: var(--gold-500);
  background: transparent;
  text-shadow: none;
  box-shadow: none;
  width: auto;
}

.ant-btn > span {
  vertical-align: middle;
}

.ant-btn-link path {
  color: var(--gold-500);
}

.ant-btn-link:hover {
  color: var(--gold-300);
  background: var(--transparent);
  border-color: transparent;
}

.ant-btn-link:hover > span {
  color: var(--gold-300);
  text-decoration: underline;
}

.ant-btn-link:focus {
  color: var(--gold-300);
  background: var(--transparent);
  text-decoration: underline;
  border-color: transparent;
}

.ant-btn-link:hover path {
  fill: var(--gold-300);
}

.ant-btn-link:active {
  color: var(--gold-400);
  background: var(--transparent);
  border-color: transparent;
  text-decoration: underline solid var(--gold-400);
}

.ant-btn-link:active path {
  fill: var(--gold-400);
}

.ant-btn-link:active span {
  color: var(--gold-400);
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0;
}

.link-black,
.link-black path {
  color: var(--dark-700);
  fill: var(--dark-700);
}

.link-black:hover,
.link-black:hover path {
  color: var(--soft-700);
  fill: var(--soft-700);
}

.link-black:active,
.link-black:active path {
  color: var(--dark-500);
  fill: var(--dark-500);
}

.ant-btn-text,
.ant-btn-text:hover,
.ant-btn-text:focus,
.ant-btn-text:active {
  all: unset;
  cursor: pointer;
  display: inline-flex;
  place-items: center;
  gap: 8px;
}

/*Disabled*/
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  background: var(--light-600);
  color: var(--soft-300);
  border: 1.5px solid var(--light-600);
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  background: transparent;
  border: 1.5px solid transparent;
  fill: var(--soft-300);
  color: var(--soft-300);
}

.ant-btn-link[disabled] span,
.ant-btn-link[disabled]:hover span,
.ant-btn-link[disabled]:focus span,
.ant-btn-link[disabled]:active span {
  background: transparent;
  color: var(--soft-300);
  text-decoration: none;
}

.ant-btn-link[disabled] path,
.ant-btn-link[disabled]:hover path,
.ant-btn-link[disabled]:focus path,
.ant-btn-link[disabled]:active path {
  fill: var(--soft-300);
}
