.ant-dropdown-menu{
  border-radius: 8px;
  box-shadow:2px 2px 40px rgba(25, 25, 25, 0.1);
}

.ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider{
  margin: 0;
}

.ant-dropdown-menu-title-content{
  line-height: 16px;
}

.ant-dropdown-menu-item:hover{
  font-weight: 600;
}