.background {
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  background-color: var(--dark-700);
  opacity: 0.7;
}

.loaderBox {
  position: relative;
  display: flex;
  width: 194px;
  height: 194px;
  justify-content: center;
  align-items: center;
}

.loadingInfo {
  z-index: 1;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  max-width: 650px;
  line-height: 28px;
  text-align: center;
  user-select: none;
  gap: 28px;
}

.loader {
  position: absolute;
  border: 2px solid var(--gold-500);
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 194px;
  height: 194px;
  background: rgba(17, 18, 19, 0.6);
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
