.ant-switch {  
  background-color: var(--light-500);
}

.ant-switch:hover {  
  background-color: var(--light-400);
}

.ant-switch:focus {
  outline: 1px solid var(--soft-300);
}

.ant-switch.ant-switch-checked{
  background-color: var(--gold-500);
}

.ant-switch.ant-switch-checked:hover {  
  background-color: var(--gold-400);
}

.ant-switch.ant-switch-checked:focus {
  box-shadow: none;
  outline: 1px solid var(--gold-600);
}

.ant-switch.ant-switch-disabled {
  opacity: 1;
  background-color: var(--light-500);
}

.ant-switch.ant-switch-disabled .ant-switch-handle::before {
  background-color: var(--light-700);
}

.ant-click-animating-node {
  display: none;
}
