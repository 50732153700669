.flexcolcenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconBox {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconBoxOK {
  composes: iconBox;
  background-color: var(--nature-400-op-10);
  color: var(--nature-500);
}

.iconBoxKO {
  composes: iconBox;
  background-color: var(--velvet-400-op-10);
  color: var(--velvet-500);
}

.iconBoxInfo {
  composes: iconBox;
  background-color: var(--gold-400-op-10);
  color: var(--gold-500);
}

.modalTitle {
  text-align: center;
  padding-top: 4px;
  margin-bottom: 0rem !important;
  padding-bottom: 24px;
}

.antmodalbody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerBtn {
  margin-top: 17px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  column-gap: 16px;
  width: auto;
}

.footerBtn button {
  width: 183px;
}

.modal {
  height: 352px;
}
