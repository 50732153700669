.ant-checkbox {
  top: 0;
}
.ant-checkbox-inner {
  border: 1px solid var(--soft-700);
  color: var(--soft-700);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border: 1px solid var(--soft-500);
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1.5px solid var(--light-700);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: inherit;
}

.ant-checkbox-checked::after {
  border: none;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid var(--gold-500);
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0.7) translate(-70%, -80%);
}

.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid var(--gold-300);
  border-top: 0;
  border-left: 0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 12px;
  height: 2px;
  background-color: var(--gold-500);
  transform: translate(-50%, -50%) scale(1);
}

.ant-checkbox-wrapper:hover .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--gold-300);
}
