.ant-slider-rail {
  height: 2px;
  margin-top: 1px;
}

.ant-slider-handle {
  outline: 1px solid var(--light-700);
  z-index: 1;
}
.ant-slider-handle:hover{
  outline: 1px solid var(--gold-500);
}

.ant-slider-handle:focus{
  background-color: var(--gold-300);
  outline: 1px solid var(--gold-300);
}

.ant-slider-handle-dragging {
  background-color: var(--gold-500) !important;
  outline: 1px solid var(--gold-500) !important;
}

.ant-slider-dot {
  background-color: transparent !important;
  border: 0px solid transparent !important;
}

.ant-slider-with-marks {
  margin-bottom: 0px !important;
}
