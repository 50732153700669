@import 'styles/ATVTheme.css';
@import 'styles/typography.css';
@import 'styles/spacing.css';
@import 'styles/colors.css';
@import 'styles/button.css';
@import 'styles/slider.css';
@import 'styles/switch.css';
@import 'styles/modal.css';
@import 'styles/checkbox.css';
@import 'styles/table.css';
@import 'styles/dropdown.css';
@import 'styles/pagination.css';
@import 'styles/result.css';
@import 'styles/input/number.css';
@import 'styles/spin.css';

/** Global styles and theming */
html,
body {
  margin: 0;
  height: 100%;
  background-color: var(--light-400);
  font-family: 'Open Sans', sans-serif;
  --zIndex-topLayer: 1000;
  --zIndex-underLayer: 1;
}
#__next {
  display: flex;
  height: 100%;
  flex-direction: column;
}

/* Autocomplete input background color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Customized elements */
/* INPUT AND INPUT NUMBER */
.ant-input {
  height: 40px;
  font-size: 12px;
  line-height: 16px;
}

.ant-input.ant-input-sm {
  height: 32px;
}

.ant-input-affix-wrapper-sm {
  padding: 0 10px;
}

.ant-input:focus,
.ant-input-number:focus {
  box-shadow: none;
  caret-color: var(--gold-300);
}

.ant-input-number:focus,
.ant-input-number-focused {
  box-shadow: none;
}

.ant-input-number-handler:active {
  background: none;
}

.ant-input-number {
  height: 40px;
  width: 100%;
  min-width: 105px;
}

.ant-input-number-handler-down-inner,
.ant-input-number-handler-up-inner {
  color: var(--dark-700);
}

.ant-input-number-handler-up:hover,
.ant-input-number-handler-down:hover {
  height: 50% !important;
}

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 50% !important;
}

.ant-input-number-disabled {
  border-color: var(--soft-300);
}

.ant-input-number-handler-wrap {
  right: 12px;
}

/* SELECT */
.ant-select {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.ant-select:hover {
  color: var(--dark-300);
}

.ant-select-arrow {
  color: var(--dark-700);
  transition: transform 0.1s ease-in-out;
}

.ant-select-open.ant-select-in-form-item {
  font-weight: 600;
}

.ant-select-open.ant-select-single.ant-select-open .ant-select-selection-item {
  font-weight: 600;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  font-weight: 600;
}

.ant-select-open .ant-select-arrow {
  transform: rotate(180deg);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: var(--gold-300);
  box-shadow: none;
}

.ant-select-focused:not(.ant-select-disabled, .ant-select-open).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--dark-300);
  box-shadow: 0 0 0 2px rgba(55, 31, 206, 0.3);
}

.ant-select-open:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--gold-300);
  box-shadow: none;
}

.ant-select:hover .ant-select-selector {
  border-color: var(--soft-500);
}

.ant-select:hover .ant-select-selection-placeholder {
  color: var(--dark-300);
}

.ant-select-dropdown:not(.ant-select-dropdown-placement-topLeft) {
  border: 1px solid var(--gold-300);
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 10px;
  box-shadow: none;
}

.ant-select-dropdown.ant-select-dropdown-placement-topLeft {
  border: 1px solid var(--soft-300);
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 10px;
  box-shadow: none;
}

.ant-select-dropdown:not(.ant-select-dropdown-placement-topLeft):after {
  content: '';
  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;
  border-top: 1px solid var(--light-700);
  margin-top: 4px;
}
.ant-select-dropdown.ant-select-dropdown-placement-topLeft:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
  border-top: 1px solid var(--light-700);
  margin-bottom: 4px;
}

.ant-select.ant-select-disabled .ant-select-selector {
  border-color: var(--soft-300);
}
.ant-select.ant-select-disabled .ant-select-selection-placeholder {
  color: var(--soft-300) !important;
}
.ant-select.ant-select-disabled .ant-select-arrow {
  color: var(--soft-300);
}

.scorebug .ant-select-clear {
  right: 43px;
  height: 16px;
  width: 16px;
  font-size: 16px;
  top: 18px;
}

.scorebug .ant-select-selection-overflow {
  max-width: 250px;
}

.ant-select-multiple .ant-select-selection-item {
  margin: unset;
  height: 22px;
  align-items: center;
  font-weight: 600;
  color: var(--soft-700);
  margin-right: 4px;
}

/* FORM */
.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 10px;
  line-height: 14px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
}

/* DIVIDER */
.ant-divider-horizontal {
  margin: 0;
  border-top: 1px solid var(--light-600);
}

/* TAG */
.ant-tag {
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 4px;
  border: none;
  color: var(--soft-700);
  background: #737f871a;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
}

.ant-form-item-tooltip svg {
  color: var(--dark-700);
}

.ant-tooltip {
  font-size: 12px;
}

.ant-tooltip-inner {
  padding: 16px;
  line-height: 16px;
  text-align: center;
}

/* scroll */

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-left: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(153, 162, 168, 0.5);
  border-radius: 5px;
}
@media screen and (max-width: 1280px) {
  body {
    min-width: 1280px;
    overflow-x: scroll;
    position: relative;
  }
}

@keyframes dynamic-icon-anim {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(22px);
  }
}
