/* Typography Weights */
.bold {
  font-weight: 700;
}

.semibold {
  font-weight: 600 !important;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

/* Headers */
h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 32px;
  line-height: 40px;
}

h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  line-height: 32px;
}

h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 28px;
}

/* Body */
.body-large {
  font-size: 14px;
  line-height: 18px;
}

.body-medium {
  font-size: 12px;
  line-height: 16px;
}

.body-small {
  font-size: 10px;
  line-height: 14px;
}

/* Caption */
.caption {
  font-size: 8px;
  line-height: 12px;
}
.text-disabled {
  color: var(--soft-300)
}
