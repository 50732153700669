.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--dark-700);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 16px;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  margin-right: 0px;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 48px;
  height: 48px;
  margin-right: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, 'Raleway', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 38px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: var(--white);
  border: 1px solid #f0f0f0;
  outline: 0;
  cursor: pointer;
  user-select: none;
  border-radius: 0px;
}
.ant-pagination-item a {
  display: flex;
  padding: 0 6px;
  color: var(--dark-700);
  transition: none;
  height: 48px;
  align-items: center;
  justify-content: center;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:hover {
  border-color: transparent !important;
  transition: all 0.3s;
  background: #f0f0f0;
}
.ant-pagination-item:hover a {
  color: var(--dark-700);
}
.ant-pagination-item:focus-visible {
  border-color: #fa9900;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a {
  color: #fa9900;
}
.ant-pagination-item-active {
  font-weight: 700;
  background: #f0f0f0;
  color: var(--dark-700);
  border-bottom: 1px solid #fa9900;
}
.ant-pagination-item-active a {
  color: var(--dark-700);
}
.ant-pagination-item-active:hover {
  color: var(--dark-700);
}
.ant-pagination-item-active:focus-visible {
  border-color: #ffae2e;
}
.ant-pagination-item-active:hover a {
  color: var(--dark-700);
}
.ant-pagination-item-active:focus-visible a {
  color: #ffae2e;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 0px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: flex;
  min-width: 48px;
  height: 48px;
  color: #111213;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, 'Raleway', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 0px;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid #f0f0f0;
  background-color: var(--white);
  align-items: center;
  justify-content: center;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: #111213;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #ffae2e;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: none;
  border-radius: 0px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link {
  color: #fa9900;
  border-color: #fa9900;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #fa9900;
  border-color: #fa9900;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: #afb6bb;
  border-color: #242729;
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: #afb6bb;
  border-color: #242729;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  margin-left: 16px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
}
.ant-pagination-next {
  border-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ant-pagination-prev {
  border-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ant-pagination-next .ant-pagination-item-link {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ant-pagination-prev .ant-pagination-item-link {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  left: -4px;
}
