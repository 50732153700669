.ant-modal-body {
  padding: 35px 40px;
  text-align: center;
}

.ant-modal-close-x {
  width: 64px;
  height: 64px;
  line-height: 64px;
}

.ant-modal {
  width: fit-content;
}

.ant-modal-footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--grey-light-700);
  padding: 35px 40px;
}
