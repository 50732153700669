.ant-table-header {
  box-shadow: 4px 4px 40px rgba(25, 25, 25, 0.12);
  border-radius: 4px;
  height: 48px;
  gap: 5px;
}
.ant-table-body {
  overflow-y: auto !important;
}

.ant-table-footer {
  background: transparent;
  padding: 24px 16px 10px 16px;
}

.ant-table-tbody .ant-table-row.ant-table-row-level-0 {
  background-color: var(--white);
}
.ant-table-tbody .ant-table-row.ant-table-row-level-0:nth-child(even) {
  background-color: var(--light-300);
}

.ant-table-tbody .ant-table-row.ant-table-row-level-0:hover {
  background-color: var(--white);
  box-shadow: 4px 4px 20px 0 rgba(25, 25, 25, 0.12);
  position: relative;
  font-weight: 700;
}

.ant-table-header > tr > td,
.ant-table-tbody > tr > td {
  border: none;
}

.ant-table-selection-col {
  width: 56px;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: inherit;
}

td.ant-table-column-sort {
  background: inherit;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 32px;
  background-color: #f0f0f0;
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}

.ant-table-tbody > tr > td.noPadding {
  padding: 0;
}
.ant-table-tbody:before {
  content: '-';
  display: block;
  line-height: 8px;
  color: transparent;
}

.ant-table-pagination.ant-pagination {
  margin-top: 96px;
}
