.ant-result {
    font-size: 14px;
    max-width: 550px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 20%;
    align-self: center;
}

.ant-result-title {
    font-size: 14px;
    font-weight: 600;
}